import { useEffect, useState } from 'react'

import { Combobox, TextInput, useCombobox } from '@mantine/core'

export interface AutocompleteItem {
  label: string
  value: string
}

export interface AutocompleteProps {
  label?: string
  placeholder?: string
  data: AutocompleteItem[]
  onSelect: (selectedItem: AutocompleteItem) => void
  required?: boolean
  error?: string
}

export default function Autocomplete({
  label,
  placeholder,
  data,
  onSelect,
  required = false,
  error = null,
}: AutocompleteProps) {
  const combobox = useCombobox()
  const [value, setValue] = useState('')
  const shouldFilterOptions = !data.some((item) => item.label === value)
  const filteredOptions = shouldFilterOptions
    ? data.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim())
      )
    : data

  const options = filteredOptions.map((item) => (
    <Combobox.Option value={item.label} key={item.label}>
      {item.label}
    </Combobox.Option>
  ))

  useEffect(() => {
    const selectedValue = data.find((item) => item.label === value)
    if (selectedValue) {
      onSelect(selectedValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, shouldFilterOptions])

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        setValue(optionValue)
        combobox.closeDropdown()
      }}
      store={combobox}
      withinPortal={false}
    >
      <Combobox.Target>
        <TextInput
          label={label}
          required={required}
          error={error}
          placeholder={placeholder}
          classNames={{
            label: 'text-xs font-medium text-doubleNickel-gray-700',
          }}
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value)
            combobox.openDropdown()
            combobox.updateSelectedOptionIndex()
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options.length === 0 ? (
            <Combobox.Empty>Nothing found</Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
