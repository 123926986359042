import { useState } from 'react'

import { ActionIcon, Group, Loader, Text } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone'
import { useForm } from '@mantine/form'
import dayjs from 'dayjs'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import Autocomplete from 'src/components/Combobox/Autocomplete/Autocomplete'
import QuestionRow from 'src/components/Inputs/QuestionRow/QuestionRow'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_PUBLIC_COMPANIES } from 'src/graphql/companies.graphql'
import { CREATE_VOE } from 'src/graphql/voe.graphql'
import IconClose from 'src/icons/IconClose'
import IconFile from 'src/icons/IconFile'
import IconUpload from 'src/icons/IconUpload'
import { formatBytes } from 'src/lib/formatters'
import MonthInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/MonthInput/MonthInput'

import AddressInput from '../ApplicantDetailsPage/tabs/ApplicationTab/components/AddressInput/AddressInput'
import PasswordInput from '../ApplicantDetailsPage/tabs/ApplicationTab/components/PasswordInput/PasswordInput'
import TextInput from '../ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'
import Header from '../DriverApplicationPage/components/Header'
import ApplicationCompletedScreen from '../DriverApplicationPage/screens/ApplicationCompletedScreen'
import PhoneInput from 'src/components/Inputs/PhoneInput/PhoneInput'

import { removeCountryCode, unformatPhoneNumber } from 'src/lib/phone.utils'
import GooglePlacePicker from '../ApplicantDetailsPage/tabs/ApplicationTab/components/AddressInput/GooglePlacePicker'
import { formatAddress } from 'src/lib/address.utils'

const MEGABYTE = 1024 ** 2

const SelectedFile = ({ file, onClose }) => {
  if (!file) return null
  return (
    <div className="flex flex-row items-center gap-3">
      <IconFile className="h-10 w-8 fill-none" />
      <div className="items-center gap-1.5">
        <Text className="text-md font-medium text-doubleNickel-gray-900">
          {file.name}
        </Text>
        <Text className="text-sm font-medium text-doubleNickel-gray-500">
          {formatBytes(file.size)}
        </Text>
      </div>
      <ActionIcon
        variant="subtle"
        className="ml-auto h-6 w-6 rounded-md bg-doubleNickel-white"
        onClick={onClose}
      >
        <IconClose className="h-3 w-3 fill-doubleNickel-white stroke-doubleNickel-gray-500" />
      </ActionIcon>
    </div>
  )
}

const ExternalVoeRequestPage = () => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [companies, setCompanies] = useState([])
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const { data } = useQuery(GET_PUBLIC_COMPANIES, {
    onCompleted: (data) => {
      const temp = data.publicCompanies.map((company) => {
        return { label: company.companyName, value: company.companyId }
      })
      setCompanies(temp)
    },
  })

  const [createVoe] = useMutation(CREATE_VOE, {
    onCompleted: async ({ createVerificationOfEmployment }) => {
      const { uploadUrl } = createVerificationOfEmployment

      await fetch(uploadUrl, {
        method: 'PUT',
        body: form.values.file,
        headers: {
          'Content-Type': form.values.file.type,
        },
      })
      setLoadingSubmit(false)
      setFormSubmitted(true)
    },
    onError: () => {
      setLoadingSubmit(false)
      toast('Unable to create VoE request. Please try again later.', 'error')
    },
  })

  const companyIds = data?.publicCompanies
    ? data.publicCompanies.map((company) => company.companyId)
    : []

  const schema = z.object({
    companyId: z.enum(companyIds, {
      errorMap: () => ({ message: 'Please select an existing company' }),
    }),
    companyName: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(300, { message: '300 characters max' }),
    dotNumber: z.string(),
    contactName: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(300, { message: '300 characters max' }),
    email: z.string().email({ message: 'Invalid email' }),
    phoneNumber: z
      .string()
      .refine(
        (data) => unformatPhoneNumber(removeCountryCode(data)).length === 10,
        {
          message: 'Phone number should have 10 digits',
        }
      ),
    faxNumber: z
      .string()
      .refine(
        (data) =>
          unformatPhoneNumber(removeCountryCode(data)).length === 10 ||
          unformatPhoneNumber(removeCountryCode(data)).length === 0,
        {
          message: 'Fax number should have 10 digits',
        }
      )
      .optional()
      .nullable(),
    driverName: z.string(),
    // fullAddress: z.string(),
    ssn: z.string().length(9, { message: 'SSN should have 9 digits' }),
    dob: z.date(),
    positionHeld: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(300, { message: '300 characters max' }),
    startDate: z.date(),
    endDate: z.date().optional(),
    reasonForLeaving: z
      .string()
      .min(1, { message: 'This field is required' })
      .max(300, { message: '300 characters max' }),
    isDriverTerminated: z.boolean(),
    isDriverSubjectToFMCSRs: z.boolean(),
    isJobDesignatedAsSafetySensitiveFunctionUnderDOT: z.boolean(),
    file: z.object({}).required(),
  })

  const form = useForm({
    initialValues: {
      companyId: '',
      companyName: '',
      dotNumber: '',
      contactName: '',
      email: '',
      phoneNumber: '',
      faxNumber: '',
      driverName: '',
      companyAddress: '',
      ssn: '',
      dob: undefined,
      positionHeld: '',
      startDate: undefined,
      endDate: undefined,
      reasonForLeaving: '',
      isDriverTerminated: false,
      isDriverSubjectToFMCSRs: true,
      isJobDesignatedAsSafetySensitiveFunctionUnderDOT: true,
      file: null,
    },
    validateInputOnBlur: true,
    validate: zodResolver(schema),
  })

  const onDrop = (files) => {
    form.setFieldValue('file', files[0])
  }

  const cleanSelectedFile = () => {
    form.setFieldValue('file', null)
  }

  const onFormSubmit = (values) => {
    setLoadingSubmit(true)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { file, ...voeInput } = values

    createVoe({
      variables: {
        input: {
          ...voeInput,
        },
      },
    })
  }

  if (formSubmitted) {
    return (
      <>
        <Header />
        <div className="flex h-screen flex-col justify-center">
          <ApplicationCompletedScreen
            title="VoE Request Submitted!"
            subTitle={'Contact support with any questions'}
            showNavigationButton={false}
          />
        </div>
      </>
    )
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => onFormSubmit(values))}
      className="flex h-[100dvh] flex-col justify-between gap-5"
    >
      <Metadata
        title="ExternalVoeRequest"
        description="ExternalVoeRequest page"
      />
      <Header />
      <div className="flex flex-1 flex-col gap-5 overflow-auto px-4 sm:px-24">
        {/* HEADER */}
        <div className="flex w-full flex-row">
          <div className="flex flex-1 flex-col">
            <div className="text-lg font-semibold text-doubleNickel-gray-900">
              VoE Request Form
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
          <div className="flex flex-col gap-6 p-6">
            <div className="text-md font-semibold text-doubleNickel-gray-900">
              Who would you like to contact?
            </div>
            <Autocomplete
              required
              label="Company Name"
              data={companies}
              onSelect={(value) => form.setFieldValue(`companyId`, value.value)}
              error={form.errors.companyId as string}
            />
          </div>
        </div>

        {/* COMPANY INFO */}
        <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
          <div className="flex flex-col gap-6 p-6">
            <div className="text-md font-semibold text-doubleNickel-gray-900">
              Company Information
            </div>
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <TextInput
                label="Company Name"
                required
                editing={true}
                className="flex-1"
                {...form.getInputProps('companyName')}
              />
              <TextInput
                label="DoT Number"
                required
                {...form.getInputProps('dotNumber')}
                editing={true}
                className="flex-1"
              />
              <TextInput
                label="Contact Name"
                required
                {...form.getInputProps('contactName')}
                editing={true}
                className="flex-1"
              />
            </div>
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <TextInput
                label="Email"
                required
                {...form.getInputProps('email')}
                editing={true}
                className="flex-1"
              />
              <PhoneInput
                className="flex-1"
                required
                value={form.values.phoneNumber as string}
                label="Phone Number"
                error={form.errors['phoneNumber']}
                onChange={(value) => {
                  form.setFieldValue('phoneNumber', value)
                }}
              />
              <PhoneInput
                className="flex-1"
                value={form.values.faxNumber as string}
                label="Fax Number"
                error={form.errors['faxNumber']}
                onChange={(value) => {
                  form.setFieldValue('faxNumber', value)
                }}
              />
            </div>
            <GooglePlacePicker
              label="Full Address"
              required
              value={form.values.companyAddress}
              handleChange={(value) => {
                form.setFieldValue(`companyAddress`, value)
              }}
            />
          </div>
        </div>

        {/* DRIVER INFO  */}
        <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
          <div className="flex flex-col gap-6 p-6">
            <div className="text-md font-semibold text-doubleNickel-gray-900">
              Driver Information
            </div>
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <TextInput
                label="Driver Name"
                required
                {...form.getInputProps('driverName')}
                editing={true}
                className="flex-1"
              />
              <PasswordInput
                label="SSN"
                required
                value={form.values.ssn as string}
                handleChange={(value) => form.setFieldValue('ssn', value)}
                error={form.errors.ssn as string}
                editing={true}
                className="flex-1"
              />
              <DatePickerInput
                classNames={{
                  label: 'text-xs font-medium text-doubleNickel-gray-700',
                }}
                label="Date of Birth"
                required
                className="flex-1"
                valueFormat="MM/DD/YYYY"
                defaultLevel="decade"
                {...form.getInputProps('dob')}
              />
            </div>
          </div>
        </div>

        {/* REQUEST CONTENT  */}
        <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
          <div className="flex flex-col gap-6 p-6">
            <div className="text-md font-semibold text-doubleNickel-gray-900">
              Request Form
            </div>
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              <TextInput
                label="Position Held"
                required
                {...form.getInputProps('positionHeld')}
                editing={true}
                className="flex-1"
              />

              <MonthInput
                editing={true}
                label="Employment Start Date"
                required
                className="flex-1"
                value={form.values.startDate}
                error={form.errors[`startDate`]}
                handleChange={(value) => form.setFieldValue(`startDate`, value)}
                _maxDate={
                  form.values.endDate
                    ? dayjs(form.values.endDate as Date).format('MM/DD/YYYY')
                    : null
                }
                {...form.getInputProps('startDate')}
              />
              <MonthInput
                editing={true}
                label="Employment End Date"
                required
                className="flex-1"
                value={form.values.endDate}
                error={form.errors[`endDate`]}
                handleChange={(value) => form.setFieldValue(`endDate`, value)}
                _minDate={
                  form.values.startDate
                    ? dayjs(form.values.startDate as Date).format('MM/DD/YYYY')
                    : null
                }
                {...form.getInputProps('endDate')}
              />
            </div>
            <TextInput
              label="Reason for Leaving"
              required
              {...form.getInputProps('reasonForLeaving')}
              editing={true}
            />
            <QuestionRow
              text="Was the driver terminated?"
              required
              value={form.values.isDriverTerminated}
              handleChange={(value) =>
                form.setFieldValue(`isDriverTerminated`, value === 'true')
              }
              editing={true}
            />
            <QuestionRow
              text="Was the driver subject to FMCSRs while employed?"
              required
              value={form.values.isDriverSubjectToFMCSRs}
              handleChange={(value) =>
                form.setFieldValue(`isDriverSubjectToFMCSRs`, value === 'true')
              }
              editing={true}
            />
            <QuestionRow
              text="Was the driver's job designated as a safety-sensitive function in a DoT regulated mode subject to Drug and Alcohol testing per 49 CFR Part 40?"
              required
              value={
                form.values.isJobDesignatedAsSafetySensitiveFunctionUnderDOT
              }
              handleChange={(value) =>
                form.setFieldValue(
                  `isJobDesignatedAsSafetySensitiveFunctionUnderDOT`,
                  value === 'true'
                )
              }
              editing={true}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
          <div className="flex flex-col gap-6 p-6">
            <div className="text-md font-semibold text-doubleNickel-gray-900">
              Upload driver consent document{' '}
              <span className="text-red-500">*</span>
            </div>
            <div className="flex flex-col justify-between gap-4 sm:flex-row">
              {!form.values.file && (
                <div className="w-full flex-row py-1">
                  <Dropzone
                    accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
                    maxFiles={1}
                    maxSize={5 * MEGABYTE}
                    onDrop={onDrop}
                    {...form.getInputProps('file')}
                  >
                    <Group className="flex flex-col">
                      <Dropzone.Accept>
                        <p>Drag and drop the file here</p>
                      </Dropzone.Accept>
                      <Dropzone.Reject>
                        <p>This file type is not supported</p>
                      </Dropzone.Reject>
                      <Dropzone.Idle>
                        <ActionIcon
                          variant="subtle"
                          className="h-10 w-10 rounded-lg border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm"
                        >
                          <IconUpload className="fill-none stroke-doubleNickel-gray-700" />
                        </ActionIcon>
                      </Dropzone.Idle>
                      <div className="flex flex-col items-center gap-1">
                        <Text className="text-sm text-doubleNickel-gray-600">
                          <span className="font-semibold text-doubleNickel-brand-500">
                            {'Click to upload '}
                          </span>
                          or drag and drop
                        </Text>
                        <Text
                          size="sm"
                          className="text-xs text-doubleNickel-gray-600"
                        >
                          PDF, PNG, JPG or JPEG (Max 5MB)
                        </Text>
                      </div>
                    </Group>
                  </Dropzone>
                </div>
              )}

              {form.values.file && (
                <>
                  <SelectedFile
                    file={form.values.file}
                    onClose={cleanSelectedFile}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mb-10 flex w-full flex-row items-center justify-end">
          {!loadingSubmit && (
            <Button
              // disabled={!form.isValid()}
              text="Submit"
              className="w-full sm:w-32"
              type="submit"
            />
          )}
          {loadingSubmit && <Loader />}
        </div>
      </div>
    </form>
  )
}

export default ExternalVoeRequestPage
